// REACT
import React from "react";
// HELPER
// CSS
// COMPONENT
import "./whitebutton.css";
// HOOK
// LIBRARY
import { NavLink } from "react-router-dom";
// DATA
// IMAGE
// ICON

function WhiteButton({ text, href, blank }) {
  return (
    <NavLink className="whiteButton" target={blank && "_blank"} to={href}>
      <span>{text}</span>
    </NavLink>
  );
}

export default WhiteButton;
