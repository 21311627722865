// REACT
import React from "react";
// HELPER
// CSS
import "./masonry.css";
// COMPONENT
// HOOK
// LIBRARY
import { useTranslation } from "react-i18next";
// DATA
// IMAGE
// ICON

function Masonry() {
  const { t } = useTranslation();

  return (
    <div className="masonryContainer">
      {/* COLUMN */}
      <div className="masonryColumn">
        <div className="masonryCard">
          <span>{t("masonry.masonry1")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry2")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry3")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry4")}</span>
        </div>
      </div>
      {/* COLUMN */}
      <div className="masonryColumn">
        <div className="masonryCard">
          <span>{t("masonry.masonry5")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry6")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry7")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry8")}</span>
        </div>
      </div>
      {/* COLUMN */}
      <div className="masonryColumn">
        <div className="masonryCard">
          <span>{t("masonry.masonry9")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry10")}</span>
        </div>
        <div className="masonryCard">
          <span>{t("masonry.masonry11")}</span>
        </div>
      </div>
    </div>
  );
}

export default Masonry;
