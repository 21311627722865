// REACT
import React from "react";
// HELPER
// CSS
import "./Contact.css";
// COMPONENT
import Footer from "./components/footer";
import ContactForm from "./components/contact";
// HOOK
// LIBRARY
import { useTranslation } from "react-i18next";
import { ScrollContainer } from "react-nice-scroll";
// DATA
// IMAGE
///import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
//import "bootstrap/dist/js/bootstrap.bundle.min";
// ICON

function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <ScrollContainer>
        <section>
          <div className="container">
            <div className="row">
              <ContactForm />
            </div>
          </div>
        </section>
        <section>
          <div className="Harita">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d434.8212010884018!2d29.189529538306957!3d41.007112423574114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab1acee889049%3A0xa6d1280265c3faf1!2sKORKMAZ%20END%C3%9CSTR%C4%B0YEL%20POMPALAR!5e0!3m2!1str!2str!4v1696961145528!5m2!1str!2str"
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <Footer />
        </section>
      </ScrollContainer>
    </>
  );
}

export default Contact;
