// REACT
import { useEffect } from "react";
// HELPER
// CSS
// COMPONENT
// HOOK
// LIBRARY
import { useLocation } from "react-router-dom";
// DATA
// IMAGE
// ICON

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default ScrollToTop;
