// REACT
// HELPER
// CSS
// COMPONENT
// HOOK
// LIBRARY
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// DATA
// IMAGE
// ICON

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector) //USER'S SYTEM LANGUAGE DETECT

  .init({
    fallbackLng: "tr",
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    // resources
  });

export default i18n;
