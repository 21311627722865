// REACT
import React, { useEffect } from "react";
// HELPER
// CSS
import "./ProductDetail.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import ProductBenefits from "./components/productbenefits";
import Footer from "./components/footer";
// HOOK
// LIBRARY
import { ScrollContainer } from "react-nice-scroll";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "js-cloudimage-360-view";
// DATA
import ProductData from "./products/ProductData";
// IMAGE
import rotater from "./img/360.png";
// ICON

function ProductDetail() {
  const { t } = useTranslation();
  let { id } = useParams();

  useEffect(() => {
    window.CI360.init();
  }, [id]);

  return (
    <ScrollContainer>
      <section>
        {ProductData.map(
          (product) =>
            product.id === id && (
              <Container key={product.id}>
                <TitleSection>
                  <h1>{product.title}</h1>
                  <p className="break-line">{t("products.productDesc")} </p>
                </TitleSection>
                <div className="productimagewrapper">
                  <div
                    className="cloudimage-360"
                    id="gurkha-suv"
                    data-folder={`/products/${product.image}/`}
                    data-filename-x="{index}.webp"
                    data-amount-x="60"
                    data-hide-360-logo
                  />
                  {/* <img src={productdetail} alt='productimage' className='productimage' /> */}
                  <img
                    src={rotater}
                    alt="rotatorimage"
                    className="rotatorimage"
                  />
                </div>
                <ProductBenefits
                  dimensions={product.dimenisons}
                  capacity={product.capacity}
                  bar={product.bar}
                  horizontal={product.horizontal}
                  vertical={product.vertical}
                  recommend={product.recommend}
                  rpm={product.rpm}
                  operating={product.operating}
                />
              </Container>
            )
        )}
        <Footer />
      </section>
    </ScrollContainer>
  );
}

export default ProductDetail;
