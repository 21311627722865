// REACT
import React from "react";
// HELPER
// CSS
import "./titlesection.css";
// COMPONENT
// HOOK
// LIBRARY
import { motion } from "framer-motion";
// DATA
// IMAGE
// ICON

function TitleSection({ background, children }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 2 }}
      className={
        background === "white"
          ? "titleSectionContainerWhite"
          : "titleSectionContainer"
      }
    >
      {children}
    </motion.div>
  );
}

export default TitleSection;
