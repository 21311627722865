import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Trans, useTranslation } from "react-i18next";
import "./contactForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "./Input";
// npm i @emailjs/browser

const Contact = () => {


  const { t } = useTranslation();
  const form = useRef();
  const [capVal, setCapVal] = useState(null);
  const [dispSuc, setDispSuc] = useState(false);
  const [dispWar, setDispWar] = useState(false);
  const [dispForm, setDispForm] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ih0rzr3",
        "template_y7eyjse",
        form.current,
        "WmsxFCbDI18JAjviR")
      .then(
        (result) => {
          console.log(result.text);
          console.log("Mesajınız gönderildi.");
          e.target.reset();
          dispSuc = setDispSuc(true);
          dispForm = setDispForm(false);
          dispWar = setDispWar(false);



        },
        (error) => {
          console.log(error.text);
          dispWar = setDispWar(true);
          dispForm = setDispForm(false);
          dispSuc = setDispSuc(false);


        }
      );
  };




  return (
    <>

      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="wrapper-contact">
                <div className="row no-gutters">
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4 py-5">
                      <h3 className="mb-4">{t("contacts.title")}</h3>
                      <div id="form-message-warning" className="mb-4" style={{ display: dispWar ? 'block' : 'none' }}>{t("contacts.warnning")}</div>
                      <div id="form-message-success" className="mb-4" style={{ display: dispSuc ? 'block' : 'none' }}>
                        {t("contacts.success")}
                      </div>
                      <form ref={form} onSubmit={sendEmail} id="contactForm" name="contactForm" className="contactForm" style={{ display: dispForm ? 'block' : 'none' }}>
                        <div className="row">
                          <Input
                            inpClass="col-md-12"
                            inpName="user_name"
                            inpPlaceHolder={t("contacts.name")}
                            inpType="text"
                            inpID="name"
                          />
                          <Input
                            inpClass="col-md-12"
                            inpName="user_email"
                            inpPlaceHolder={t("contacts.email")}
                            inpType="text"
                            inpID="email"
                          />
                          <Input
                            inpClass="col-md-12"
                            inpName="message"
                            inpPlaceHolder={t("contacts.message")}
                            inpType="textarea"
                            inpID="message"
                          />
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="submit" value={t("contacts.send")} className="btn btn-primary" hidden={!capVal} />
                              <div className="submitting"></div>
                            </div>
                          </div>
                          <div className="col-md-12" hidden={capVal}>
                            <div className="form-group">
                              <ReCAPTCHA
                                sitekey="6LfEd8koAAAAAAF9SeqIHWoxf7GIDNsgpNnlVRQq"
                                onChange={val => setCapVal(val)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div className="info-wrap w-100 p-md-5 p-4 py-5 img">
                      <h3>{t("contacts.information")}</h3>
                      <p className="mb-4">{t("contacts.infoDesc")}</p>
                      <div className="dbox w-100 d-flex align-items-start">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-map-marker"></span>
                        </div>
                        <div className="text pl-3">
                          <p>{/*<span>Address:</span>*/}<span>Emek Mahallesi Atatürk Caddesi No: 4/1 İç Kapı : 3 Sancaktepe/İstanbul</span> </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-phone"></span>
                        </div>
                        <div className="text pl-3">
                          <p><span><a href="tel:+902163647174">+90 216 364 7174</a></span> </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-paper-plane"></span>
                        </div>
                        <div className="text pl-3">
                          <p><span><a href="mailto:info@korkmazendustri.com.tr">info@korkmazendustri.com.tr</a></span></p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-globe"></span>
                        </div>
                        <div className="text pl-3">
                          <p><span><a href="https://www.korkmazendustri.com">www.korkmazendustri.com</a></span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  );
};
export default Contact;