// REACT
import React from "react";
// HELPER
// CSS
import "./AboutUs.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import Footer from "./components/footer";
// HOOK
// LIBRARY
import { ScrollContainer } from "react-nice-scroll";
import { useTranslation } from "react-i18next";
// DATA
// IMAGE
// ICON

function AboutUs() {
  const { t } = useTranslation();

  return (
    <ScrollContainer>
      <section>
        <Container>
          <TitleSection>
            <h1>{t("aboutus.aboutusTitle")}</h1>
            <p>{t("aboutus.aboutusDesc")}</p>
          </TitleSection>
        </Container>
        <Container background={"white"}>
          <div className="aboutContent">
            <h3>{t("aboutus.aboutus1")}</h3>
            <p>{t("aboutus.aboutus1Desc")}</p>
          </div>
          <div className="aboutContent">
            <h3>{t("aboutus.aboutus2")}</h3>
            <p>{t("aboutus.aboutus2Desc")}</p>
          </div>{" "}
          <div className="aboutContent">
            <h3>{t("aboutus.aboutus3")}</h3>
            <p>{t("aboutus.aboutus3Desc")}</p>
          </div>
          <div className="aboutContent">
            <h3>{t("aboutus.aboutus4")}</h3>
            <p>{t("aboutus.aboutus4Desc")}</p>
          </div>
        </Container>
        <Footer revert />
      </section>
    </ScrollContainer>
  );
}

export default AboutUs;
