// REACT
import React from "react";
// HELPER
// CSS
import "./Products.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import Footer from "./components/footer";
import WhiteButton from "./components/whitebutton";
// HOOK
// LIBRARY
import { ScrollContainer } from "react-nice-scroll";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
// DATA
import ProductData from "./products/ProductData";
// IMAGE
// ICON

function Product({ img, href, name, dimension }) {
  const { t } = useTranslation();

  return (
    <div className="productsCard">
      <img
        src={`/products/${img}/cover.webp`}
        className="productImg"
        alt="productImg"
      ></img>
      <div className="productContent">
        <h1>{name}</h1>
        <div className="productContentBottom">
          <p>{t("products.connectiondimensions")}</p>
          <span>{dimension}</span>
        </div>
        <div className="right">
          <WhiteButton
            href={`/products/${href}`}
            text={t("products.see-details-button")}
          />
        </div>
      </div>
    </div>
  );
}

function Products() {
  const { t } = useTranslation();
  return (
    <ScrollContainer>
      <section>
        <Container>
          <TitleSection>
            <h1>{t("products.productsTitle")}</h1>
            <p>{t("products.productsDesc")}</p>
          </TitleSection>
        </Container>
        <Container>
          <div className="productsWrapper">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
              className="productsContainer double"
            >
              {ProductData.slice(0, 2).map((product) => (
                <Product
                  key={product.id}
                  name={product.title}
                  dimension={product.dimenisons}
                  img={product.image}
                  href={product.id}
                />
              ))}
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
              className="productsContainer triple"
            >
              {ProductData.slice(2, 5).map((product) => (
                <Product
                  key={product.id}
                  name={product.title}
                  dimension={product.dimenisons}
                  img={product.image}
                  href={product.id}
                />
              ))}
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
              className="productsContainer double"
            >
              {ProductData.slice(5).map((product) => (
                <Product
                  key={product.id}
                  name={product.title}
                  dimension={product.dimenisons}
                  img={product.image}
                  href={product.id}
                />
              ))}
            </motion.div>
          </div>
        </Container>
        <Footer />
      </section>
    </ScrollContainer>
  );
}

export default Products;
