import React from "react";
import "./benefits.css";
import design from "../img/design.png";
import assembly from "../img/assembly.webp";
import qualitycontrol from "../img/qualitycontrol.webp";
import { t } from "i18next";
import { motion } from "framer-motion";

function BenefitsLobe() {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer"
        id="principle"
      >
        <img src={design} className="benefitImg" alt="benefitImg" />
        <div className="leftColumn">
          <h1>{t("benefits.principle")}</h1>
          <h4>{t("benefits.principleSub")}</h4>
          <span>{t("benefits.principleDesc")}</span>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer reverse"
        id="tier"
      >
        <div className="leftColumn">
          <h1>{t("benefits.building")}</h1>
          <h4>{t("benefits.buildingSub")}</h4>
          <span>{t("benefits.buildingDesc")}</span>
        </div>
        <img src={assembly} className="benefitImg" alt="benefitImg" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer"
        id="avantage"
      >
        <img src={qualitycontrol} className="benefitImg" alt="benefitImg" />
        <div className="leftColumn">
          <h1>{t("benefits.advantages")}</h1>
          <h4>{t("benefits.advantagesSub")}</h4>
          <span>{t("benefits.advantagesDesc")}</span>
        </div>
      </motion.div>
    </>
  );
}

export default BenefitsLobe;
