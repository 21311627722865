// REACT
import React, { useState } from "react";
// HELPER
// CSS
import "./navbar.css";
// COMPONENT
// HOOK
// LIBRARY
import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
// DATA
// IMAGE
// ICON
import logo from "../img/logolight.svg";
import instagram from "../icon/instagram.svg";
import twitter from "../icon/twitter.svg";
import medium from "../icon/medium.svg";
import linkedin from "../icon/linkedin.svg";
import youtube from "../icon/youtube.svg";
import menu from "../icon/menu.svg";
import menuclose from "../icon/menuclose.svg";

function NavbarLink({ href, children, ...props }) {
  return (
    <li className="menuItem">
      <NavLink
        className={({ active }) => active && "activeItem"}
        to={href}
        {...props}
      >
        {children}
      </NavLink>
    </li>
  );
}

function MenuItems() {
  const { t } = useTranslation();
  return (
    <ul className="menuItemss">
      <NavbarLink href={"/"}>{t("navbar.navHome")}</NavbarLink>
      <NavbarLink href={"/aboutus"}>{t("navbar.navAboutUs")}</NavbarLink>
      <NavbarLink href={"/aboutlobe"}>{t("navbar.navAboutLobe")}</NavbarLink>
      <NavbarLink href={"/products"}>{t("navbar.navProducts")}</NavbarLink>
      <NavbarLink href={"/options"}>{t("navbar.navOptions")}</NavbarLink>
      <NavbarLink href={"/contact"}>{t("navbar.navContact")}</NavbarLink>
    </ul>
  );
}

function Navbar() {
  const [isOpen, setisOpen] = useState(false);

  const { t, i18n } = useTranslation();

  const clickHandle = async (lang) => {
    await i18n.changeLanguage(lang);
    // .then(() => { localStorage.setItem("language", i18n.language) })
  };
  const options = [
    { value: "tr", label: "Türkçe" },
    { value: "en", label: "English" },
    { value: "ru", label: "Русский" },
  ];
  return (
    <>
      <nav>
        <div className="navbarContainer">
          <div className="navbarWrapper">
            <NavLink to={"/"}>
              <img src={logo} alt="logo" className="logo" />
            </NavLink>
            <MenuItems />
            {/* <div className="socialContainer">
              <img src={instagram} alt="socialLogo" className="socialLogo" />
              <img src={twitter} alt="socialLogo" className="socialLogo" />
              <img src={medium} alt="socialLogo" className="socialLogo" />
              <img src={linkedin} alt="socialLogo" className="socialLogo" />
              <img src={youtube} alt="socialLogo" className="socialLogo" />
            </div> */}
            <div className="languageContainerDesktop">
              <span onClick={() => clickHandle("tr")} className="language">
                TR
              </span>
              |
              <span onClick={() => clickHandle("en")} className="language">
                EN
              </span>
              |
              <span onClick={() => clickHandle("ru")} className="language">
                RU
              </span>
            </div>
            
            {isOpen ? (
              <img
                onClick={() => setisOpen(!isOpen)}
                src={menuclose}
                alt="menuIcon"
                className="menuIcon"
              />
            ) : (
              <img
                onClick={() => setisOpen(!isOpen)}
                src={menu}
                alt="menuIcon"
                className="menuIcon"
              />
            )}
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              animate="open"
              initial="collapsed"
              exit="collapsed"
              variants={{
                open: { opacity: 1, x: 0 },
                collapsed: { opacity: 0, x: 500 },
              }}
              className={"navbarMobileContainer"}
            >
              <ul className="menuItemss">
                <NavbarLink onClick={() => setisOpen(false)} href={"/"}>
                  {t("navbar.navHome")}
                </NavbarLink>
                <NavbarLink onClick={() => setisOpen(false)} href={"/aboutus"}>
                  {t("navbar.navAboutUs")}
                </NavbarLink>
                <NavbarLink
                  onClick={() => setisOpen(false)}
                  href={"/aboutlobe"}
                >
                  {t("navbar.navAboutLobe")}
                </NavbarLink>
                <NavbarLink onClick={() => setisOpen(false)} href={"/products"}>
                  {t("navbar.navProducts")}
                </NavbarLink>
                <NavbarLink onClick={() => setisOpen(false)} href={"/options"}>
                  {t("navbar.navOptions")}
                </NavbarLink>
                <NavbarLink onClick={() => setisOpen(false)} href={"/contact"}>
                  {t("navbar.navContact")}
                </NavbarLink>
              </ul>
              <div className="socialContainer2">
                <img src={instagram} alt="socialLogo" className="socialLogo" />
                <img src={twitter} alt="socialLogo" className="socialLogo" />
                <img src={medium} alt="socialLogo" className="socialLogo" />
                <img src={linkedin} alt="socialLogo" className="socialLogo" />
                <img src={youtube} alt="socialLogo" className="socialLogo" />
              </div>
              <div className="languageContainer">
                <span onClick={() => clickHandle("tr")} className="language">
                  TR
                </span>
                |
                <span onClick={() => clickHandle("en")} className="language">
                  EN
                </span>
                |
                <span onClick={() => clickHandle("ru")} className="language">
                  RU
                </span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
