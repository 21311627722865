// REACT
import React from "react";
// HELPER
// CSS
import "./AboutUs.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import Footer from "./components/footer";
import BenefitsLobe from "./components/benefitslobe";
import Masonry from "./components/masonry";
// HOOK
// LIBRARY
import { ScrollContainer } from "react-nice-scroll";
import { Trans, useTranslation } from "react-i18next";
// DATA
// IMAGE
// ICON

function AboutLobe() {
  const { t } = useTranslation();

  return (
    <ScrollContainer>
      <section>
        <Container>
          <TitleSection>
            <h1>{t("aboutlobe.aboutLobeTitle")}</h1>
            <Trans>
              <p>{t("aboutlobe.aboutLobeDesc")}</p>
            </Trans>
          </TitleSection>
        </Container>
        <Container background={"white"}>
          <div className="aboutContent">
            <h3>{t("aboutlobe.whatisTitle")}</h3>
            <Trans>
              <p>{t("aboutlobe.aboutLobep1")}</p>
            </Trans>
          </div>
        </Container>
        <Container>
          <BenefitsLobe />
        </Container>
        <Container background={"white"}>
          <TitleSection background={"white"}>
            <p>{t("masonry.masonryDesc")}</p>
          </TitleSection>
          <Masonry />
        </Container>
        <Footer revert />
      </section>
    </ScrollContainer>
  );
}

export default AboutLobe;
