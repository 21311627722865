// REACT
import React from "react";
// HELPER
// CSS
import "./components/options.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import Footer from "./components/footer";
// HOOK
// LIBRARY
import { ScrollContainer } from "react-nice-scroll";
import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";

// DATA
// IMAGE
// ICON
import milgroup from "./img/milgroup.webp";
import yarikpompa from "./img/yarikpompa.webp";
import isiceketli from "./img/options/isiceketlilobe.webp";
import mobilpompa from "./img/options/mobilpompa.webp";
import yanpompa from "./img/options/yanpompa.webp";
import lobedisli from "./img/options/lobeDisli.webp";
import baglanti from "./img/options/baglanti.webp";
import govde from "./img/options/govde.webp";

function Options() {
  const { t } = useTranslation();
  return (
    <ScrollContainer>
      <section>
        <Container>
          <TitleSection>
            <h1>{t("options.optionsTitle")}</h1>
            <img src={yarikpompa} alt="yarikpompa" style={{ width: "100%" }} />
            <p>{t("options.optionsDesc")}</p>
          </TitleSection>
        </Container>
        <Container>
          {/* OPTION 1 */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionColumn"
          >
            <div className="optionsContainer">
              <img
                src={mobilpompa}
                className="optionsImg"
                alt="donersalmastra"
              />
              <div className="leftColumn">
                <h1>{t("options.option1Title")}</h1>
                <span>
                  <Trans>{t("options.option1Desc")}</Trans>
                </span>
              </div>
            </div>
            <Trans>
              <span>{t("options.option1Desc2")}</span>{" "}
            </Trans>
          </motion.div>
          {/* OPTION 2 */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionsContainer reverse"
          >
            <div className="leftColumn">
              <h1>{t("options.option2Title")}</h1>
              <span>{t("options.option2Desc")}</span>
            </div>
            <img src={isiceketli} className="optionsImg" alt="sabitsalmastra" />
          </motion.div>
          {/* OPTION 3 */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionsContainer"
          >
            <img src={yanpompa} className="optionsImg" alt="paslanmazgovde" />
            <div className="leftColumn">
              <h1>{t("options.option3Title")}</h1>
              <span>{t("options.option3Desc")}</span>
            </div>
          </motion.div>
          {/* OPTION 4 */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionsContainer reverse"
          >
            <div className="leftColumn">
              <h1>{t("options.option4Title")}</h1>
              <span>{t("options.option4Desc")}</span>
            </div>
            <img src={govde} className="optionsImg" alt="sabitsalmastra" />
          </motion.div>
          {/* OPTION 5 */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionsContainer "
          >
            {" "}
            <img src={baglanti} className="optionsImg" alt="lob dişli" />
            <div className="leftColumn">
              <Trans>
                <h1>{t("options.option5Title")}</h1>
              </Trans>
              <Trans>
                <span>{t("options.option5Desc")}</span>
              </Trans>
            </div>
          </motion.div>
          {/* OPTION 6*/}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            className="optionsContainer reverse"
          >
            <div className="leftColumn">
              <h1>{t("options.option6Title")}</h1>
              <Trans>
                <span>{t("options.option6Desc")}</span>
              </Trans>
            </div>
            <img src={lobedisli} className="optionsImg" alt="sabitsalmastra" />
          </motion.div>
        </Container>
        <Footer />
      </section>
    </ScrollContainer>
  );
}

export default Options;
