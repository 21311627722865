// REACT
import React from "react";
import reportWebVitals from "./reportWebVitals";
// HELPER
// CSS
import "./index.css";
import "./App.css";
// COMPONENT
import App from "./App";
// HOOK
// LIBRARY
import { createRoot } from "react-dom/client";
import { BrowserRouter} from "react-router-dom";
import "./i18n";
// DATA
// IMAGE
// ICON
// FONTS
import "./font/HelveticaNowDisplay-Thin.ttf";
import "./font/HelveticaNowDisplay-ExtLt.ttf";
import "./font/HelveticaNowDisplay-Light.ttf";
import "./font/HelveticaNowDisplay-Regular.ttf";
import "./font/HelveticaNowDisplay-Medium.ttf";
import "./font/HelveticaNowDisplay-Bold.ttf";
import "./font/HelveticaNowDisplay-ExtraBold.ttf";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
