// REACT
import React from "react";
// HELPER
// CSS
import "./productbenefits.css";
// COMPONENT
import PbCard from "./pbCard";
// HOOK
// LIBRARY
import { Trans, useTranslation } from "react-i18next";
// DATA
// IMAGE
// ICON

function ProductBenefits({
  dimensions,
  capacity,
  bar,
  horizontal,
  vertical,
  recommend,
  rpm,
  operating,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="pbContainer">
        <PbCard title={t("products.connectiondimensions")} desc={dimensions} />
        <PbCard title={t("products.maxcapacity")} desc={capacity} />
        <PbCard title={t("products.maxbar")} desc={bar} />
        <PbCard
          title={t("products.horizontal")}
          desc={horizontal + t("products.meter")}
        />
        <PbCard
          title={t("products.vertical")}
          desc={vertical + t("products.meter")}
        />
        {/* <PbCard title={t("products.recommended")} desc={recommend} />
        <PbCard title={t("products.maxrpm")} desc={rpm} /> */}
        <PbCard title={t("products.operating")} desc={operating} />
      </div>{" "}
      <Trans>
        <span className="pbNote">
          <div dangerouslySetInnerHTML={{ __html: t("products.note") }}></div>
        </span>
      </Trans>
    </div>
  );
}

export default ProductBenefits;
