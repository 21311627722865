// REACT
import React from "react";
// HELPER
import ScrollToTop from "../helpers/ScrollToTop";
// CSS
import "./footer.css";
// COMPONENT
// HOOK
// LIBRARY
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
// DATA
import ProductData from "../products/ProductData";
// IMAGE
// ICON
import pdfIcon from "../icon/pdf.svg";
import darklogo from "../img/logodark.svg";
import instagram from "../icon/instagram.svg";
import twitter from "../icon/twitter.svg";
import medium from "../icon/medium.svg";
import linkedin from "../icon/linkedin.svg";
import youtube from "../icon/youtube.svg";

function NavbarLink({ href, children, onClick, ...props }) {
  return (
    <NavLink to={href} onClick={onClick} {...props}>
      <li className="menuItem">{children}</li>
    </NavLink>
  );
}
function Footer({ revert }) {
  const { t } = useTranslation();
  var year = new Date().getFullYear();
  return (
    <div className={revert ? "footerContainer revert" : "footerContainer"}>
      <div className="footerTop">
        <div className="footerWrapper">
          <img src={darklogo} className="footerLogo" alt="footerLogo" />
          <div className="footerTopWrapper">
            <div className="columnWrapper">
              <div className="footerColumn">
                <span>{t("footer.quickmenu")}</span>
                <ul>
                  <NavbarLink href={"/aboutus"}>
                    {t("navbar.navAboutUs")}
                  </NavbarLink>
                  <NavbarLink href={"/aboutlobe"}>
                    {t("navbar.navAboutLobe")}
                  </NavbarLink>
                  <NavbarLink href={"/products"}>
                    {t("navbar.navProducts")}
                  </NavbarLink>
                  <NavbarLink href={"/options"}>
                    {t("navbar.navOptions")}
                  </NavbarLink>
                  <NavbarLink href={"/contact"}>
                    {t("navbar.navContact")}
                  </NavbarLink>
                </ul>
              </div>
              <div className="footerColumn">
                <span>{t("navbar.navAboutLobe")}</span>
                <ul>
                  <NavbarLink href={"/aboutlobe#principle"}>
                    {t("aboutlobe.whatisTitle")}
                  </NavbarLink>
                  <NavbarLink href={"/aboutlobe"}>
                    {t("footer.workingprinciple")}
                  </NavbarLink>
                  <NavbarLink href={"/aboutlobe"}>
                    {t("footer.construction")}
                  </NavbarLink>
                  <NavbarLink href={"/aboutlobe"}>
                    {t("benefits.advantages")}
                  </NavbarLink>
                  <NavbarLink href={"/aboutlobe#avantage"}>
                    {t("footer.usageareas")}
                  </NavbarLink>
                </ul>
              </div>
              <div className="footerColumn">
                <span>{t("navbar.navProducts")}</span>
                <ul>
                  {ProductData.map((product) => (
                    <NavbarLink
                      href={`/products/${product.id}`}
                      onClick={ScrollToTop}
                    >
                      {product.title} (
                      {product.dimenisons.substring(
                        0,
                        product.dimenisons.indexOf("/")
                      )}
                      )
                    </NavbarLink>
                  ))}
                </ul>
              </div>
              <div className="footerColumn">
                <span>{t("footer.certificates")}</span>

                <ul>
                  <NavbarLink
                    target="_blank"
                    href={"/certificates/korkmaz_iso_22000.pdf"}
                  >
                    <img src={pdfIcon} alt="pdfIcon" />
                    &nbsp;ISO 22000
                  </NavbarLink>
                  <NavbarLink
                    target="_blank"
                    href={"/certificates/korkmaz_iso_9001.pdf"}
                  >
                    <img src={pdfIcon} alt="pdfIcon" />
                    &nbsp;ISO 9001
                  </NavbarLink>
                  <NavbarLink
                    target="_blank"
                    href={"/certificates/korkmaz_yerlimalibelgesi.pdf"}
                  >
                    <img src={pdfIcon} alt="pdfIcon" />
                    &nbsp;{t("footer.certificateyerlimali")}
                  </NavbarLink>

                  {/* <li>{t("footer.models")}</li>
                                    <li>{t("footer.connectionoptions")}</li>
                                    <li>{t("footer.mountingoptions")}</li>
                                    <li>{t("footer.thermaljacket")}</li>
                                    <li>{t("footer.packing")}</li>
                                    <li>{t("footer.carthridge")}</li>
                                    <li>{t("footer.inverter")}</li> */}
                </ul>
              </div>
            </div>
            <div className="footerColumn">
              <span>{t("footer.footerTitle")}</span>
              <p>{t("footer.footerDesc")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="footerBottomWrapper">
          <span>© Copyright {year} Korkmaz - Endüstriyel Pompalar</span>
          <div className="copyrightContent">
            <span>
              {t("footer.designed")}
              <a href="https://nullusunus.com" rel="noreferrer" target="_blank">
                &nbsp;Nullus Unus Services
              </a>{" "}
            </span>
            <div className="footerSocialContainer">
              <img
                src={instagram}
                alt="footerSocialLogo"
                className="footerSocialLogo"
              />
              <img
                src={twitter}
                alt="footerSocialLogo"
                className="footerSocialLogo"
              />
              <img
                src={medium}
                alt="footerSocialLogo"
                className="footerSocialLogo"
              />
              <img
                src={linkedin}
                alt="footerSocialLogo"
                className="footerSocialLogo"
              />
              <img
                src={youtube}
                alt="footerSocialLogo"
                className="footerSocialLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
