// REACT
import React from "react";
// HELPER
// CSS
// COMPONENT
// HOOK
// LIBRARY
// DATA
// IMAGE
// ICON

function Container({ background, children, nopadding }) {
  return (
    <div className={background === "white" ? "containerWhite" : "container"}>
      <div className={nopadding === true ? "wrapper nopadding" : "wrapper"}>
        {children}
      </div>
    </div>
  );
}

export default Container;
