// REACT
import React from "react";
// HELPER
// CSS
import "./ourfactory.css";
// COMPONENT
// HOOK
// LIBRARY
import { useTranslation } from "react-i18next";
// DATA
// IMAGE
import salmastras from "../img/salmastras.webp";
import milgroup from "../img/milgroup.webp";
import paslanmazgovde from "../img/paslanmazgovde.webp";
// ICON

function OurFactory() {
  const { t } = useTranslation();
  return (
    <div className="ourfactoryContainer">
      <div className="factoryCard">
        <img src={milgroup} className="factoryImg" alt="factoryImg" />
        <h3>{t("ourfactory.professional")}</h3>
        <p>{t("ourfactory.proDesc")}</p>
      </div>
      <div className="factoryCard">
        <img src={paslanmazgovde} className="factoryImg" alt="factoryImg" />
        <h3>{t("ourfactory.services")}</h3>
        <p>{t("ourfactory.serDesc")}</p>
      </div>
      <div className="factoryCard">
        <img src={salmastras} className="factoryImg" alt="factoryImg" />
        <h3>{t("ourfactory.engineering")}</h3>
        <p>{t("ourfactory.engDesc")}</p>
      </div>
    </div>
  );
}

export default OurFactory;
