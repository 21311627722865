// REACT
// HELPER
import ScrollToTop from "./helpers/ScrollToTop";
// CSS
import "./App.css";
// COMPONENT
import Navbar from "./components/navbar";
import WhatsNullApp from "./components/whatsapp-button-nullus";
import { Trans, useTranslation } from "react-i18next";
// HOOK
// LIBRARY
import { Routes, Route } from "react-router-dom";
// DATA

//whatsapp buton için aşağıdakini aç
//import { FloatingWhatsApp } from 'react-floating-whatsapp'

// IMAGE
// ICON

// PAGES
import Homepage from "./Homepage";
import AboutUs from "./AboutUs";
import AboutLobe from "./AboutLobe";
import Products from "./Products";
import Contact from "./Contact";
import ProductDetail from "./ProductDetail";
import Options from "./Options";

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="spacer" />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/aboutlobe" element={<AboutLobe />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products/:id" element={<ProductDetail />} />
        <Route path="/options" element={<Options />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
      <ScrollToTop />
      <WhatsNullApp />
    </>
  );
}

export default App;
