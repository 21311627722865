// REACT
import React from "react";
// HELPER
// CSS
import "react-nice-scroll/dist/styles.css";
// COMPONENT
import Container from "./components/container";
import TitleSection from "./components/titlesection";
import WhiteButton from "./components/whitebutton";
import OurFactory from "./components/ourfactory";
import Benefits from "./components/benefits";
import Footer from "./components/footer";
// HOOK
// LIBRARY
import { Trans, useTranslation } from "react-i18next";
import { ScrollContainer, SequenceSection } from "react-nice-scroll";
import { motion } from "framer-motion";
// DATA
// IMAGE
import heroimg from "./img/banner_img.webp";
import heroimgm from "./img/banner_img_m.webp";
import anasayfaImg from "./img/anasayfa.webp";
import anasayfaImgmM from "./img/anasayfa_m.webp";
// ICON

function Homepage() {
  const { t } = useTranslation();
  return (
    <ScrollContainer>
      <section>
        <Container nopadding={true}>
          <TitleSection>
            <div className="hero">
              <h1>{t("hero.heroTitle")}</h1>
              <p>
                <Trans>{t("hero.heroDesc")}</Trans>
              </p>
            </div>
          </TitleSection>
          <motion.img
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            src={heroimg}
            className="heroimg korkmazImg"
            alt="heroimg"
          />
          <motion.img src={heroimgm} className="heroimg_m" alt="heroimg" />
        </Container>
        <Container>
          <TitleSection>
            <h2>{t("whatwedo.whatwedo")}</h2>
            <h1>{t("whatwedo.whatwedoTitle")}</h1>
            <p>{t("whatwedo.whatwedoDesc")}</p>
          </TitleSection>
        </Container>
      </section>
      <Container>
        <motion.img
          initial={{ opacity: 0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 2 }}
          src={anasayfaImg}
          className="heroimg "
          alt="heroimg"
        />
        <motion.img src={anasayfaImgmM} className="heroimg_m" alt="heroimg" />
      </Container>
      <section>
        <Container>
          <TitleSection>
            <p>{t("whatwedo.whatwedosubDesc")}</p>
          </TitleSection>
          <WhiteButton
            text={t("whatwedo.discoverproducts")}
            href={"/products"}
          />
        </Container>
        <Container background={"white"}>
          <TitleSection background={"white"}>
            <h2>{t("ourfactory.ourfactory")}</h2>
            <h1>{t("ourfactory.ourfactoryTitle")}</h1>
            <p>{t("ourfactory.ourfactoryDesc")}</p>
          </TitleSection>
          <OurFactory />
        </Container>
        <Container>
          <TitleSection>
            <h2>{t("howwework.howwework")}</h2>
            <h1>{t("howwework.howweworkTitle")}</h1>
            <p>{t("howwework.howweworkDesc")}</p>
          </TitleSection>
          <Benefits />
        </Container>
        <Footer />
      </section>
    </ScrollContainer>
  );
}

export default Homepage;
