// REACT
import React from "react";
// HELPER
// CSS
import "./benefits.css";
// COMPONENT
// HOOK
// LIBRARY
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
// DATA
// IMAGE
import design from "../img/design.png";
import assembly from "../img/assembly.webp";
import qualitycontrol from "../img/qualitycontrol.webp";
// ICON

// REACT
// HELPER
// CSS
// COMPONENT
// HOOK
// LIBRARY
// DATA
// IMAGE
// ICON

function Benefits() {
  const { t } = useTranslation();

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer reverse"
      >
        <div className="leftColumn">
          <h1>{t("howwework.design")}</h1>
          <span>{t("howwework.designDesc")}</span>
        </div>
        <img src={design} className="benefitImg" alt="benefitImg" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer"
      >
        <img src={assembly} className="benefitImg" alt="benefitImg" />
        <div className="leftColumn">
          <h1>{t("howwework.assembly")}</h1>
          <span>{t("howwework.assemblyDesc")}</span>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="benefitsContainer reverse"
      >
        <div className="leftColumn">
          <h1>{t("howwework.qc")}</h1>
          <span>{t("howwework.qcDesc")}</span>
        </div>
        <img src={qualitycontrol} className="benefitImg" alt="benefitImg" />
      </motion.div>
    </>
  );
}

export default Benefits;
