import React from "react";

const Input = ({inpClass, inpName, inpPlaceHolder, inpType, inpID}) => {
    return (
        <div className={inpClass}>
            <div className="form-group">
                <input type={inpType} className="form-control" name={inpName} id={inpID} placeholder={inpPlaceHolder} />
            </div>
        </div>
    )
}

export default Input;