// REACT
import React from "react";
// HELPER
// CSS
import "./productbenefits.css";
// COMPONENT
// HOOK
// LIBRARY
import { motion } from "framer-motion";
// DATA
// IMAGE
// ICON
import vector from "../icon/Vector.svg";

function PbCard({ title, desc }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 2 }}
      className="pbCard"
    >
      <img src={vector} className="pbIcon" alt="pbIcon" />
      <div className="pbContent">
        <p>{title}</p>
        <span>{desc}</span>
      </div>
    </motion.div>
  );
}

export default PbCard;
