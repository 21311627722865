const ProductData = [
  {
    id: "1",
    image: "dn25",
    title: "KLP-25",
    dimenisons: 'DN 25/1"',
    capacity: "2.500 l/s",
    bar: "6 bar",
    horizontal: "150 ",
    vertical: "6 ",
    recommend: "1.5kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "2",
    image: "dn32",
    title: "KLP-32",
    dimenisons: 'DN 32/1 1/4"',
    capacity: "3.500 l/s",
    bar: "6 bar",
    horizontal: "150 ",
    vertical: "6 ",
    recommend: "0.75kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "3",
    image: "dn40",
    title: "KLP-40",
    dimenisons: 'DN 40/1 1/2"',
    capacity: "4.000 l/s",
    bar: "7 bar",
    horizontal: "180 ",
    vertical: "8 ",
    recommend: "1.5kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "4",
    image: "dn50",
    title: "KLP-50",
    dimenisons: 'DN 50/2" ',
    capacity: "6.000 l/s",
    bar: "8 bar",
    horizontal: "200 ",
    vertical: "10 ",
    recommend: "2.2kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "5",
    image: "dn65",
    title: "KLP-65",
    dimenisons: 'DN 65/2 1/2" ',
    capacity: "15.000 l/s",
    bar: "15 bar",
    horizontal: "250 ",
    vertical: "12 ",
    recommend: "4kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "6",
    image: "dn80",
    title: "KLP-80",
    dimenisons: 'DN 80/3" ',
    capacity: "25.000 l/s",
    bar: "18 bar",
    horizontal: "300 ",
    vertical: "25 ",
    recommend: "5.5kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
  {
    id: "7",
    image: "dn100",
    title: "KLP-100",
    dimenisons: 'DN 100/4" ',
    capacity: "50.000 l/s",
    bar: "20 bar",
    horizontal: "400 ",
    vertical: "40 ",
    recommend: "7.5kw",
    rpm: "322 d/dk",
    operating: "0-150 °C",
  },
];

export default ProductData;
